import { initializeApp } from 'firebase/app'
import {
  collection,
  doc,
  onSnapshot,
  getFirestore,
  where,
  setDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  getDoc,
  orderBy,
  query as fsQuery,
} from 'firebase/firestore'

import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth'
import {
  equalTo,
  getDatabase,
  onValue,
  orderByChild,
  query,
  ref,
  set,
  get,
} from 'firebase/database'
import {
  getStorage,
  ref as storeRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
//import * as functions from 'firebase/functions'
import config from './config'
import { useEffect, useState } from 'react'
import { GROUPS } from '../../constants/splinterGroups'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { BsChatLeftText, BsChatLeftTextFill } from 'react-icons/bs'

class Firebase {
  constructor() {
    const app = initializeApp(config)
    //this.analytics = getAnalytics(app)
    this.auth = getAuth(app)
    this.db = getDatabase(app)
    this.fs = getFirestore(app)
    this.store = getStorage(app)
    this.analytics = getAnalytics(app)
    this.user = (uid) => ref(this.db, `users/${uid}`)
    this.userPath = (uid) => `users/${uid}`
    this.attendeeDocRef = ref(
      this.db,
      '1Z2XCB43MGcR6Ik50WUaFs5M9Rm2tlfPDXksusC5CfTY/attendees/'
    )
    this.focusGroupLeaderRef = ref(
      this.db,
      '1Z2XCB43MGcR6Ik50WUaFs5M9Rm2tlfPDXksusC5CfTY/fgLeaders'
    )
    this.posterDbRef = ref(
      this.db,
      '1Z2XCB43MGcR6Ik50WUaFs5M9Rm2tlfPDXksusC5CfTY/posters'
    )
    this.formDocRef = ref(
      this.db,
      '1eAgaxnDTa7UQqfZxio8fmhbV8hdYzfE1n0-c0RwSjgo/formAttendees'
    )
  }
  // ** User Api **
  //user = (uid) => ref(this.db, `users/${uid}`)
  //users = () => ref(this.db, 'users')
  // attendeeDocRef = ref(
  //   this.db,
  //   '1uVnlbyLxAF-MN8vA_2fIh5aNUnMr5gTv6P_CyjxmU5s/attendees/'
  // )

  // ** Auth API **
  getUserInfo = async (email) => {
    let user
    const promises = []
    //const attendeeSS =
    promises.push(
      get(
        query(
          this.attendeeDocRef,
          orderByChild('email'),
          equalTo(email.toLowerCase())
        )
      )
    )
    //const fgSS =
    promises.push(
      get(
        query(
          this.focusGroupLeaderRef,
          orderByChild('email'),
          equalTo(email.toLowerCase())
        )
      )
    )
    promises.push(get(this.formDocRef))
    const [attendeeSS, fgSS, formSS] = await Promise.all(promises)
    const dbAttendees = attendeeSS.val()
    const fgAttendees = fgSS.val()
    const formAttendees = formSS.val()
    if (dbAttendees) {
      const dbAttendee = Object.values(dbAttendees).find(
        (attendee) => !!attendee?.email
      )
      user = { ...dbAttendee, isAdmin: fgSS.exists() }
    } else if (fgAttendees) {
      const fgAttendee = Object.values(fgAttendees).find(
        (attendee) => !!attendee?.email
      )
      user = { ...fgAttendee, isAdmin: true }
    } else if (formAttendees) {
      const formAttendee = Object.values(formAttendees).find(
        (attendee) =>
          attendee.emailAnyCase.toLowerCase() === email.toLowerCase()
      )
      if (formAttendee) {
        user = {
          ...formAttendee,
          email: formAttendee.emailAnyCase.toLowerCase(),
        }
      }
    }
    if (!user) {
      throw Error('No one has registered with this email address')
    }
    return user
  }

  doCreateUserWithEmailAndPassword = async ({ user, email, password }) => {
    const userCred = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    )
    user.uid = userCred.user.uid
    await set(this.user(user.uid), user)
  }

  doSignInWithEmailAndPassword = async ({ user, email, password }) => {
    const userCred = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    )
    user.uid = userCred.user.uid
    await set(this.user(user.uid), user)
  }

  doSignOut = () => this.auth.signOut()

  //** Merge Auth and DB User API */

  onAuthUserListener = async (next, fallback) => {
    const delay = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        let userSnapshot = await get(this.user(authUser.uid))
        if (userSnapshot.val() === null) {
          await delay(1000)
          userSnapshot = await get(this.user(authUser.uid))
        }
        authUser = userSnapshot.val()
        next(authUser)
      } else {
        fallback()
      }
    })
  }

  useAttendeeEmailsDB = () => {
    const [attendeeEmails, setAttendeeEmails] = useState(new Set())
    useEffect(() => {
      const unsubscribe = onValue(this.attendeeListRef, (snapshot) => {
        let attendees = snapshot?.val()
        let attendeeEmails = attendees
          ? new Set(
              Object.values(attendees).map((attendeeObj) =>
                attendeeObj.email?.toString().toLowerCase()
              )
            )
          : new Set()
        setAttendeeEmails(attendeeEmails)
      })
      return () => unsubscribe()
    }, [])
    return attendeeEmails
  }

  /* Talk API */
  useTalkOrder = ({ splinterGroup, blockId }) => {
    const [talkOrder, setTalkOrder] = useState([])
    useEffect(() => {
      const unsubscribe = onSnapshot(
        doc(this.fs, 'focusGroups', splinterGroup, 'blocks', blockId),
        (doc) => {
          setTalkOrder(doc.data()?.talkArr ? [...doc.data().talkArr] : [])
        }
      )
      return unsubscribe
    }, [splinterGroup, blockId])
    return talkOrder
  }

  useFsBlockInfo = ({ splinterGroup, blockId }) => {
    const [fsBlockInfo, setFsBlockInfo] = useState({})
    useEffect(() => {
      const unsubscribe = onSnapshot(
        doc(this.fs, 'focusGroups', splinterGroup, 'blocks', blockId),
        (doc) => {
          const blockInfo = doc.data() || {}
          blockInfo.talkArr = blockInfo?.talkArr || []
          setFsBlockInfo(blockInfo)
        }
      )
      return unsubscribe
    }, [splinterGroup, blockId])
    return fsBlockInfo
  }

  useTalks = ({ splinterGroup }) => {
    const [talks, setTalks] = useState({})
    useEffect(() => {
      const q = query(
        collection(this.fs, 'talks'),
        where(splinterGroup, '==', true)
      )
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const talkDict = {}
        querySnapshot.forEach((doc) => {
          talkDict[doc.id] = { id: doc.id, ...doc.data() }
        })
        setTalks(talkDict)
      })
      return unsubscribe
    }, [splinterGroup])
    return talks
  }

  useQuestions = ({ splinterGroup }) => {
    const [questions, setQuestions] = useState({})
    useEffect(() => {
      const q = query(
        collection(this.fs, 'questions'),
        where(splinterGroup, '==', true)
      )
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const talkDict = {}
        querySnapshot.forEach((doc) => {
          talkDict[doc.id] = { id: doc.id, ...doc.data() }
        })
        setQuestions(talkDict)
      })
      return unsubscribe
    }, [splinterGroup])
    return questions
  }

  postTalk = async ({ splinterGroups = [], data, file = null, blockId }) => {
    let isAnEdit = true
    if (!data?.id) {
      isAnEdit = false
      const docRef = await addDoc(collection(this.fs, 'talks'), data)
      data.id = docRef.id
      const allSplinterGroups = [...Object.keys(GROUPS)]
      for (const group of allSplinterGroups) {
        data[group] = splinterGroups.includes(group)
      }
    }
    if (file) {
      const fileRef = storeRef(this.store, `talks/${data.id}/${file.name}`)
      await uploadBytes(fileRef, file)
      data.url = await getDownloadURL(fileRef)
      data.file = fileRef.fullPath
    }
    await setDoc(doc(this.fs, `talks/${data.id}`), data, { merge: true })
    const promises = []

    if (!isAnEdit) {
      for (const group of splinterGroups) {
        promises.push(
          await setDoc(
            doc(this.fs, 'focusGroups', group, 'blocks', blockId),
            { talkArr: arrayUnion(data.id) },
            { merge: true }
          )
        )
      }
    }
    await Promise.all(promises)
  }

  postQuestion = async ({ splinterGroups = [], data }) => {
    const docRef = await addDoc(collection(this.fs, 'questions'), data)
    data.id = docRef.id
    const allSplinterGroups = [...Object.keys(GROUPS)]
    for (const group of allSplinterGroups) {
      data[group] = splinterGroups.includes(group)
    }
    const promises = []
    promises.push(
      await setDoc(doc(this.fs, `questions/${data.id}`), data, { merge: true })
    )
    await Promise.all(promises)
  }
  deleteQuestion = async ({ questionId = '' }) => {
    const qRef = doc(this.fs, 'questions', questionId)
    deleteDoc(qRef)
  }

  updateQuestion = async ({ question }) => {
    const qRef = doc(this.fs, 'questions', question.id)
    updateDoc(qRef, question)
  }

  deleteTalk = async ({
    splinterGroups = [],
    blockId,
    talkId = '',
    filePath = '',
  }) => {
    for (const group of splinterGroups) {
      await setDoc(
        doc(this.fs, 'focusGroups', group, 'blocks', blockId),
        { talkArr: arrayRemove(talkId) },
        { merge: true }
      )
    }
    const talkRef = doc(this.fs, 'talks', talkId)
    deleteDoc(talkRef)

    if (filePath) {
      const fileRef = storeRef(this.store, filePath)
      deleteObject(fileRef)
    }
  }

  deletePlenary = async ({ talkId = '' }) => {
    const talkRef = doc(this.fs, 'talks', talkId)
    let talk = await getDoc(talkRef)
    talk = talk.data()

    if (talk) {
      deleteDoc(talkRef)
    }

    if (talk?.filePath) {
      const fileRef = storeRef(this.store, talk.filePath)
      deleteObject(fileRef)
    }
  }

  moveTalk = async ({
    oldSplinterGroups = [],
    newSplinterGroups = [],
    oldBlock,
    newBlock,
    talkId,
  }) => {
    let promises = []
    for (const group of oldSplinterGroups) {
      promises.push(
        setDoc(
          doc(this.fs, `focusGroups/${group}/blocks/${oldBlock}`),
          { talkArr: arrayRemove(talkId) },
          { merge: true }
        )
      )
    }
    for (const group of newSplinterGroups) {
      promises.push(
        setDoc(
          doc(this.fs, `focusGroups/${group}/blocks/${newBlock}`),
          { talkArr: arrayUnion(talkId) },
          { merge: true }
        )
      )
    }
    const talkData = {}
    const splinterGroups = [...Object.keys(GROUPS)]
    for (const group of splinterGroups) {
      talkData[group] = newSplinterGroups.includes(group)
    }

    promises.push(
      setDoc(doc(this.fs, 'talks', talkId), talkData, { merge: true })
    )
    await Promise.all(promises)
  }

  reorderTalk = async ({ splinterGroups, blockId, topIndex }) => {
    let promises = []
    for (const group of splinterGroups) {
      promises.push(
        getDoc(doc(this.fs, `focusGroups/${group}/blocks/${blockId}`))
      )
    }
    const docs = await Promise.all(promises)

    promises = []
    for (const doc of docs) {
      const talkArr = doc.data().talkArr
      const topId = talkArr[topIndex]
      const bottomId = talkArr[topIndex + 1]
      talkArr[topIndex] = bottomId
      talkArr[topIndex + 1] = topId
      promises.push(setDoc(doc.ref, { talkArr: talkArr }, { merge: true }))
    }
    await Promise.all(promises)
  }

  downloadZipOfTalks = async ({ talkIds, splinterGroup, session }) => {
    const jszip = new JSZip()
    let talkDocsPromises = []
    try {
      for (let i = 0; i < talkIds.length; i++) {
        let talkId = talkIds[i]
        talkDocsPromises.push(getDoc(doc(this.fs, `talks/${talkId}`)))
      }
      const talkDocs = await Promise.all(talkDocsPromises)
      const talkFilePromises = []
      const talkFileName = []
      for (let i = 0; i < talkIds.length; i++) {
        const doc = talkDocs[i].data()
        if (doc && doc.file) {
          talkFilePromises.push(fetch(doc.url))
          talkFileName.push(
            (doc.name || doc.host || '') +
              '-' +
              doc.title +
              '.' +
              doc.file.split('.').pop()
          )
        }
      }
      let talkFiles = await Promise.all(talkFilePromises)

      let talkBlogPromises = []
      for (let file of talkFiles) {
        const promise = async () => {
          let blob = file.blob()
          return blob
        }
        talkBlogPromises.push(promise())
      }
      const talkBlobs = await Promise.all(talkBlogPromises)
      for (let i = 0; i < talkFileName.length; i++) {
        jszip.file(
          ('00' + (i + 1)).slice(-2) + '-' + talkFileName[i],
          talkBlobs[i]
        )
      }
      const blob = await jszip.generateAsync({ type: 'blob' })
      saveAs(blob, splinterGroup + '-' + session + '.zip')
    } catch (e) {
      console.error(e)
    }
  }

  postVideoLink = async ({ link, linkInfo, blockId, splinterGroups = [] }) => {
    const promises = []
    for (const group of splinterGroups) {
      const ref = doc(this.fs, 'focusGroups', group, 'blocks', blockId)
      promises.push(
        setDoc(ref, { link: link, linkInfo: `${linkInfo}` }, { merge: true })
      )
    }
    await Promise.all(promises)
  }

  postStudentOrPlenaryTalk = async ({ group, data, file, id }) => {
    if (!data.id) {
      data.id = id
      await setDoc(doc(this.fs, 'talks', id), data, { merge: true })
      const groups = ['plenary', 'student']
      for (const g of groups) {
        data[g] = group === g
      }
    }
    if (file) {
      const fileRef = storeRef(this.store, `talks/${data.id}-${file.name}`)
      await uploadBytes(fileRef, file)
      data.url = await getDownloadURL(fileRef)
      data.file = fileRef.fullPath
    }
    await setDoc(doc(this.fs, `talks/${data.id}`), data, { merge: true })
  }

  usePosters = ({ researchAreas }) => {
    const [posters, setPosters] = useState([])

    useEffect(() => {
      const queryConstraints = []
      if (researchAreas && researchAreas.length) {
        queryConstraints.push(where('researchArea', 'in', researchAreas))
      }
      queryConstraints.push(orderBy('id'))
      const q = fsQuery(collection(this.fs, 'posters'), ...queryConstraints)
      const posterCollection = []
      const unsubscribe = onSnapshot(q, (snapshot) => {
        snapshot.forEach((posterSS) => {
          const data = posterSS.data()
          if (!!data.deleted) {
            return
          }
          posterCollection.push(data)
        })
        setPosters(posterCollection)
      })
      return unsubscribe
    }, [researchAreas])
    return posters
  }

  usePoster = ({ id }) => {
    const [poster, setPoster] = useState({})

    useEffect(() => {
      const unsubscribe = onSnapshot(
        doc(this.fs, 'posters', id),
        (snapshot) => {
          setPoster(snapshot.exists ? snapshot.data() : {})
        }
      )
      return unsubscribe
    }, [id])
    return poster
  }

  usePosterComments = ({ id }) => {
    const [replies, setReplies] = useState([])
    useEffect(() => {
      if (!id) return
      const q = fsQuery(
        collection(this.fs, `posterComments/${id}/comments`),
        orderBy('time')
      )
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let newReplies = []
        snapshot.forEach((doc) => {
          return newReplies.push({ id: doc.id, ...doc.data() })
        })
        setReplies(newReplies)
      })
      return () => unsubscribe()
    }, [id])
    return replies
  }

  updatePoster = async ({ data, pdfFile, videoFile }) => {
    if (pdfFile) {
      const fileRef = storeRef(this.store, `posters/${data.id}-${pdfFile.name}`)
      await uploadBytes(fileRef, pdfFile)
      data.pdfUrl = await getDownloadURL(fileRef)
      data.pdfFile = fileRef.fullPath
    }
    if (videoFile) {
      const fileRef = storeRef(
        this.store,
        `posters/${data.id}-${videoFile.name}`
      )
      await uploadBytes(fileRef, videoFile)
      data.videoUrl = await getDownloadURL(fileRef)
      data.videoFile = fileRef.fullPath
    }
    setDoc(doc(this.fs, 'posters', '' + data.id), data, { merge: true })
  }

  useStudentRepTalks = () => {
    const [talks, setTalks] = useState([])
    useEffect(() => {
      const q = fsQuery(collection(this.fs, `studentRep`))
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let newTalks = []
        snapshot.forEach((doc) => {
          return newTalks.push(doc.data())
        })
        setTalks(newTalks)
      })
      return () => unsubscribe()
    }, [])
    return talks
  }
  postStudentRepTalk = async ({ data, pic, video }) => {
    if (!data.id) {
      const docRef = await addDoc(collection(this.fs, 'studentRep'), data)
      data.id = docRef.id
    }
    if (pic) {
      const picRef = storeRef(this.store, `studentRep/${data.id}-${pic.name}`)
      await uploadBytes(picRef, pic)
      data.picUrl = await getDownloadURL(picRef)
      data.picPath = picRef.fullPath
    }
    if (video) {
      const vidRef = storeRef(this.store, `studentRep/${data.id}-${video.name}`)
      await uploadBytes(vidRef, video)
      data.videoUrl = await getDownloadURL(vidRef)
      data.videoPath = vidRef.fullPath
    }
    await setDoc(doc(this.fs, `studentRep/${data.id}`), data, { merge: true })
  }
  deleteStudentRepTalk = async ({ data }) => {
    const promises = []
    if (data.picPath) {
      promises.push(deleteObject(storeRef(this.store, data.picPath)))
    }
    if (data.videoPath) {
      promises.push(deleteObject(storeRef(this.store, data.videoPath)))
    }
    promises.push(deleteDoc(doc(this.fs, 'studentRep', data.id)))
    await Promise.all(promises)
  }

  addMiscMeeting = async ({ link = '', info = '', session = '' }) => {
    const ref = doc(this.fs, 'meetings', session)
    await setDoc(ref, { link: link, linkInfo: `${info}` }, { merge: true })
  }
  useMiscMeeting = ({ session = '' }) => {
    const [meeting, setMeeting] = useState({})
    useEffect(() => {
      const unsubscribe = onSnapshot(
        doc(this.fs, 'meetings', session),
        (snapshot) => {
          setMeeting(snapshot.exists ? snapshot.data() : {})
        }
      )
      return unsubscribe
    }, [session])
    return meeting
  }
}

export default Firebase
