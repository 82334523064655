import React, { useEffect, useState } from 'react'
import { withAuthentication } from '../Session'
import { BLOCKS } from '../../constants/blocks'
import { GROUPS } from '../../constants/splinterGroups'
import { ROOMS } from '../../constants/rooms'
import { Table } from 'react-bootstrap'
import * as ROUTES from '../../constants/routes'
import map from '../../imgs/map.png'

import { NavLink, useLocation, useNavigate } from 'react-router-dom'

const Schedule = (props) => {
  const [selectedDay, setSelectedDay] = useState('')
  const days = [
    'student',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
  ]
  const todayHash = `#${days[new Date().getDay()]}`

  const navigate = useNavigate()
  const { pathname, hash, key } = useLocation()
  useEffect(() => {
    if (hash === '' && new Date() > new Date('2024-06-24')) {
      navigate(`${todayHash}`)
    } else {
      setSelectedDay(hash.slice(1))
    }
    return () => {}
  }, [pathname, hash, key, navigate, todayHash]) // do this on route change

  const DayTabs = () => {
    return (
      <div className='flex flex-wrap justify-center gap-2 '>
        {days.map((day) => (
          <div
            className={`p-2 rounded text-center cursor-pointer ${
              selectedDay !== day
                ? 'bg-[#8c8cd2]'
                : 'bg-[#2d5e99] text-white border-1 border-black'
            }`}
            onClick={() => navigate('#' + day)}
            key={'tab' + day}
          >
            {day === 'student' ? (
              <div>Sunday</div>
            ) : (
              <div>{day.charAt(0).toUpperCase() + day.slice(1)}</div>
            )}
          </div>
        ))}
        <div
          className={`py-2 px-4 rounded text-center cursor-pointer ${
            selectedDay !== 'all'
              ? 'bg-[#8c8cd2]'
              : 'bg-[#2d5e99] text-white border-black border-1'
          }`}
          onClick={() => navigate('#all')}
          key={'all'}
        >
          All
        </div>
      </div>
    )
  }

  return (
    <div>
      <h1 className='text-center py-3 text-5xl'>Schedule</h1>
      <DayTabs />
      <div>
        {days.includes(selectedDay) ? (
          <Day
            dayBlock={BLOCKS[selectedDay]}
            key={selectedDay}
            day={selectedDay}
          />
        ) : (
          days.map((day, ind) => (
            <Day dayBlock={BLOCKS[day]} key={ind} day={day} />
          ))
        )}
      </div>
      <div className='flex flex-col justify-center items-center'>
        <h2 className='p2 text-xl'>Map</h2>
        <img src={map} alt='Map of Conference Hall' className='py-2 sm:px-2' />
      </div>
    </div>
  )
}

const Day = ({ dayBlock, day, ...props }) => {
  let ind = 0
  let blocks = []
  while (dayBlock[ind]) {
    blocks.push(dayBlock[ind])
    ind++
  }
  const makeTable = (blocks, day) => {
    let head = (
      <thead>
        <tr>
          <th className='text-center w-10 lg:w-36'>
            <div className='hidden lg:block'>Mountain Time</div>
            <div className='block lg:hidden'>MT</div>
          </th>
          <th></th>
          <th className='text-center w-10 sm:w-32'>Room</th>
        </tr>
      </thead>
    )
    let body = []
    blocks.forEach((block, ind) => {
      let somethingInLastColOfHeader =
        block.groups.includes('plenary') || block.groups.length === 0
      body.push(
        <tr key={ind}>
          <td>{block.time}</td>
          {dayBlock.dayInfo.dayName === 'student' &&
          block.name.includes('Student') ? (
            <td>
              <strong>
                <NavLink
                  className='text-blue-500 hover:text-red-500'
                  to={'/' + ROUTES.STUDENTSCHEDULE}
                >
                  {block.name}
                </NavLink>
              </strong>
            </td>
          ) : block.name.includes('Plenary') ? (
            <td>
              <strong>
                <NavLink
                  className='text-blue-500 hover:text-red-500'
                  to={'/' + ROUTES.PLENARY}
                >
                  {block.name}
                </NavLink>
              </strong>
            </td>
          ) : block.name.includes('SBCCI') ? (
            <td>
              <strong>{block.name}</strong>
            </td>
          ) : block.name.includes('Student Rep') ? (
            <td>
              <strong>
                <NavLink
                  className='text-blue-500 hover:text-red-500'
                  to={'/studentRep'}
                >
                  {block.name}
                </NavLink>
              </strong>
            </td>
          ) : (
            <td>
              <strong>{block.name}</strong>
            </td>
          )}
          <td>
            {somethingInLastColOfHeader && (
              <h3>
                {block.name.includes('Poster') ||
                block.name.includes('GEMEE') ||
                block.name.includes('Banquet') ||
                block.name.includes('Queer') ? (
                  <span>
                    {block.rooms && block.rooms.length && (
                      <RoomKey room={block.rooms[0]} />
                    )}
                  </span>
                ) : block.name.includes('Icebreaker') ? (
                  <span>
                    <h6>{block.rooms}</h6>
                  </span>
                ) : (
                  <span>
                    {block?.rooms?.length ? (
                      <span>
                        {block.rooms && block.rooms.length && (
                          <RoomKey room={block.rooms[0]} />
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </span>
                )}
              </h3>
            )}
          </td>
        </tr>
      )
      block.groups.forEach((group, ind) => {
        if (
          !block.name.includes('Plenary') &&
          dayBlock.dayInfo.dayName !== 'sunday' &&
          !block.name.includes('Student Rep') &&
          !block.name.includes('GEMEE') &&
          !block.groups.includes('SHINE')
        ) {
          body.push(
            <tr key={block.name + group + ind}>
              <td></td>
              <td>
                {typeof group === 'string' ? (
                  GROUPS[group] ? (
                    <span>
                      <NavLink
                        className='text-blue-500 hover:text-red-500'
                        to={`/groups/${group}#${block.id}`}
                      >
                        <div className='indent-[-2em] pl-[2em]'>
                          {GROUPS[group] && GROUPS[group].longName}
                        </div>
                      </NavLink>
                    </span>
                  ) : (
                    group
                  )
                ) : (
                  <div>
                    {group.map((group) => {
                      if (
                        block.id &&
                        !GROUPS[group].blocks.includes(block.id)
                      ) {
                      }
                      return GROUPS[group] ? (
                        <div key={group}>
                          <span>
                            <NavLink
                              className='text-blue-500 hover:text-red-500'
                              to={`/groups/${group}#${block.id}`}
                            >
                              <div className='pl-[2em] indent-[-2em]'>
                                {GROUPS[group].longName}
                              </div>
                            </NavLink>
                          </span>
                        </div>
                      ) : (
                        <p key={group}>{group}</p>
                      )
                    })}
                  </div>
                )}
              </td>
              <td>
                <h3>
                  <RoomKey room={block.rooms[ind]} />
                </h3>
              </td>
            </tr>
          )
        }
      })
    })

    return (
      <div className='sm:px-2'>
        <Table bordered hover size='sm'>
          {head}
          <tbody>{body}</tbody>
        </Table>
      </div>
    )
  }
  return (
    <div id={day}>
      <div className='text-center text-2xl p-3'>
        {dayBlock.dayInfo.date && dayBlock.dayInfo.date}
      </div>
      {makeTable(blocks, dayBlock.dayInfo.dayName)}
    </div>
  )
}

const RoomKey = ({ room }) => {
  const key = ROOMS[room] || { title: '', displayShort: '' }
  return (
    <div
      className='flex justify-center items-center h-full text-center'
      title={key.title}
    >
      <div className='block sm:hidden'>{key.displayShort}</div>
      <div className='hidden sm:block'>{key.title}</div>
    </div>
  )
}

export default withAuthentication(Schedule)
