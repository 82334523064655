import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FirebaseContext } from '../../server/Firebase'
import uploadingGif from '../../imgs/uploading.gif'
import { ToggleButton } from 'react-bootstrap'

const AddVideoModal = ({
  thisBlockId,
  thisFocusGroup,
  blocks,
  partners,
  initialVideoLink = '',
  initialVideoInfo = '',
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [videoLink, setVideoLink] = useState(initialVideoLink)
  const [videoInfo, setVideoInfo] = useState(initialVideoInfo)
  const [blockIds, setBlockIds] = useState(new Set([thisBlockId]))
  const [savedLink, setSavedLink] = useState('')
  const [savedInfo, setSavedInfo] = useState('')
  const [deleteSelected, setDeleteSelected] = useState(false)
  const firebase = useContext(FirebaseContext)

  const toggleModal = (bool = null) => {
    setVideoLink(initialVideoLink)
    setVideoInfo(initialVideoInfo)
    setBlockIds(new Set([thisBlockId]))
    if (bool != null) {
      setShowModal(!showModal)
    } else {
      setShowModal(bool)
    }
  }
  const onSessionCheck = ({ target: { id, checked } }) => {
    const setCopy = new Set(blockIds)
    checked ? setCopy.add(id) : setCopy.delete(id)
    setBlockIds(setCopy)
  }

  const onRemoveClicked = (e) => {
    const checked = e.currentTarget.checked
    if (checked) {
      setSavedLink(videoLink)
      setSavedInfo(videoInfo)
      setVideoLink('')
      setVideoInfo('')
    } else {
      setVideoInfo(savedInfo)
      setVideoLink(savedLink)
    }
    setDeleteSelected(checked)
  }

  return (
    <>
      <Button
        type='button'
        variant='outline-secondary'
        size='sm'
        onClick={toggleModal}
        key={thisBlockId + thisFocusGroup + 'initialButton'}
      >
        {initialVideoLink ? 'Change Meeting' : 'Add Meeting'}
      </Button>
      <Modal
        show={showModal}
        onHide={() => toggleModal(false)}
        key={thisBlockId + thisFocusGroup + 'modal'}
        size='lg'
      >
        <Modal.Header closeButton key={thisBlockId + thisFocusGroup + 'header'}>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add a Meeting Link
          </Modal.Title>
        </Modal.Header>
        <Modal.Body key={thisBlockId + thisFocusGroup + 'body'}>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsUploading(true)
              try {
                const promises = []
                for (const blockId of blockIds) {
                  promises.push(
                    firebase.postVideoLink({
                      link: videoLink,
                      linkInfo: videoInfo,
                      blockId,
                      splinterGroups: [...partners[blockId], thisFocusGroup],
                    })
                  )
                }
                await Promise.all(promises)
              } catch (error) {
                console.error(error)
              }
              setIsUploading(false)
              setShowModal(false)
            }}
          >
            <Form.Group
              controlId='link'
              key={thisBlockId + thisFocusGroup + 'link'}
            >
              <Form.Label>Video Link</Form.Label>
              <Form.Control
                type='text'
                placeholder='Video Link'
                onChange={(e) => setVideoLink(e.target.value)}
                value={videoLink}
                disabled={deleteSelected}
              />
            </Form.Group>
            <br />
            <Form.Group
              controlId='info'
              key={thisBlockId + thisFocusGroup + 'info'}
            >
              <Form.Label>Video Info</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Any additional information regarding the meeting link, including (for example) how to call in.'
                value={videoInfo}
                disabled={deleteSelected}
                onChange={(e) => setVideoInfo(e.target.value)}
              />
            </Form.Group>
            <br />
            <br />
            <Form.Group key={thisBlockId + thisFocusGroup + 'otherSessions'}>
              <Form.Label>Is this the same for any other sessions?</Form.Label>
              {blocks.map((block) => {
                return (
                  <span key={thisFocusGroup + block.id}>
                    {block.id !== 'unscheduled' && (
                      <Form.Check
                        type={'checkbox'}
                        id={block.id}
                        label={block.name}
                        disabled={block.id === thisBlockId}
                        defaultChecked={
                          block.id === thisBlockId || blockIds.has(block.id)
                        }
                        onChange={onSessionCheck}
                      />
                    )}
                  </span>
                )
              })}
            </Form.Group>
            <br />
            <ToggleButton
              className='mb-2'
              id='toggle-check'
              type='checkbox'
              variant='outline-danger'
              checked={deleteSelected}
              value='1'
              onChange={onRemoveClicked}
            >
              {' '}
              Remove All Meeting Information
            </ToggleButton>

            <br />
            <br />
            <Button
              variant='primary'
              type='submit'
              key={thisBlockId + thisFocusGroup + 'submit'}
            >
              Submit
            </Button>
            {isUploading && (
              <img
                src={uploadingGif}
                alt='Spinning circle around the word uploading'
              ></img>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddVideoModal
