import React, { useContext, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
//import { compose } from 'react-recompose'

//import { SignUpLink } from '../SignUp'
// import { withFirebase } from '../../server/Firebase'
import * as ROUTES from '../../constants/routes'
import { PASSWORD } from './usernames'
import { Button, Form } from 'react-bootstrap'
// import './index.css'
import { FirebaseContext } from '../../server/Firebase'
import { AuthUserContext } from '../Session'

const SignInPage = ({ firebase, ...props }) => (
  <div>
    <div className='grid grid-cols-1 sm:grid-cols-2 px-2 pt-3 gap-4 grow-0'>
      <SignInForm />
      <div>
        <h2 className='text-3xl text-center p-2'>Online Registration</h2>
        {Date.now() > new Date('2024-06-23') ? (
          <p>
            If you are a <strong>remote attendee</strong> who has not already
            registered or your email is not recognized, please fill out{' '}
            <strong>
              <a
                href='https://forms.gle/EwLhf4WecB884QG8A'
                target='_blank'
                rel='noopener noreferrer'
                title='Link to online registration form'
                className='text-blue-600 hover:text-red-800'
              >
                this form
              </a>
            </strong>
            .
          </p>
        ) : (
          <p>
            If you have not already registered, please register{' '}
            <a
              href='https://gemworkshop.org/registration/'
              target='_blank'
              rel='noopener noreferrer'
              title='Link to online registration form'
              className='text-blue-600 hover:text-red-800'
            >
              here
            </a>
            . You will get access to the app within 24 hours.
          </p>
        )}
      </div>
    </div>
    <div className='flex justify-center align-center text-center grow p-2 pt-5'>
      <p>If you cannot access Google services, you will need to use a VPN.</p>
    </div>
  </div>
)

const SignInForm = (props) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [error, setError] = useState({})
  const onSubmit = async (event) => {
    event.preventDefault()
    setError({})
    let user
    const delay = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }
    try {
      user = await firebase.getUserInfo(email)
      await firebase.doSignInWithEmailAndPassword({
        user,
        email,
        password: PASSWORD,
      })
      navigate('/' + ROUTES.SCHEDULE)
    } catch (err) {
      let ultimateErr = err
      if (err.message.includes('user-not-found')) {
        try {
          await firebase.doCreateUserWithEmailAndPassword({
            user,
            email,
            password: PASSWORD,
          })
          await delay(1005)
          navigate('/' + ROUTES.SCHEDULE)
        } catch (err2) {
          ultimateErr = err2
        }
      }
      setEmail('')
      setError(ultimateErr)
      console.error(ultimateErr)
    }
    // try {
    //   await firebase.doCreateUserWithEmailAndPassword(email, PASSWORD)
    //   setEmail('')
    //   setError({})
    //   navigate('/' + ROUTES.SCHEDULE)
    // } catch (err) {
    //   let ultimateError = err
    //   if (err.message.includes('FIRESTORE (7.14.5)')) {
    //     err.message =
    //       'If you are seeing this message, you are logged in! Please click "Schedule".'
    //   }
    //   if (err.message.includes('already')) {
    //     try {
    //       await firebase.doSignInWithEmailAndPassword(email, PASSWORD)
    //       setEmail('')
    //       setError({})
    //       navigate('/' + ROUTES.SCHEDULE)
    //     } catch (err) {
    //       ultimateError = err
    //     }
    //   }
    //   setError(ultimateError)
    //   console.error(ultimateError)
    // }
  }
  return !user ? (
    <div>
      <h1 className='text-3xl text-center p-2'>Sign In</h1>
      <Form onSubmit={(e) => onSubmit(e)}>
        <div className='text-center'>
          <Form.Label>
            Please enter the email address you used to register for the
            conference:
          </Form.Label>
          <br />
          <div className='flex items-center justify-center'>
            <input
              placeholder='Your email'
              type='text'
              onChange={(e) => setEmail(e.target.value.toLowerCase())}
              className='w-[15em] p-2 border-1 border-black rounded'
            />
            <input
              type='submit'
              disabled={email === ''}
              style={{ display: 'none' }}
              className='text-center'
            />
            <Button
              type='submit'
              disabled={email === ''}
              style={{ marginLeft: '5px' }}
            >
              Enter
            </Button>
          </div>
          {error && <p>{error.message}</p>}
        </div>
      </Form>
    </div>
  ) : (
    <Navigate to='/schedule' />
  )
}
export default SignInPage

export { SignInForm }
