import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'
import { withAuthentication } from '../Session'
import Comments from '../Comments'

const Poster = (section = 'all') => {
  let { posterId } = useParams()
  const firebase = useContext(FirebaseContext)
  let poster = firebase.usePoster({ id: posterId })
  return (
    <div>
      <div
        className='topRow'
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        {/* {prev && (
          <a href={`${ROUTES.POSTERS}/${prev}`}>
            <AiOutlineLeft size='50' title='Go to previous poster' />
          </a>
        )} */}
        <div className='text' style={{ flexGrow: 5 }}>
          <h3 className='text-center py-3 text-3xl'>{poster.postertitle}</h3>
          <h5 className='text-center text-xl'>
            {poster.fullname}
            {/* {poster.label && (
            <h5 className={'left'}>
              <Link url={poster.spawnUrl} />{' '}
              {`${poster.researchArea} - ${poster.label}`}
            </h5>
          )} */}
            <p>
              {poster.day &&
                `This poster will be presented at the ${
                  poster.day === 'Tue' ? 'Tuesday' : 'Thursday'
                } poster session.`}
            </p>
          </h5>
          {poster.abstract && (
            <div>
              <h6 className='text-xl p-2 pb-0'>Abstract</h6>
              <p className='p-2'>{poster.abstract}</p>
            </div>
          )}
        </div>
        {/* {next && (
          <a href={`${ROUTES.POSTERS}/${next}`}>
            <AiOutlineRight size='50' title='Go to next poster' />
          </a>
        )} */}
      </div>
      {/* <Button onClick={() => onClickUploadThisPosterIntoGT()}>
        Upload poster
      </Button> */}
      {/*<Button onClick={() => onClickWriteLabels()}>Make labels</Button> */}
      {/* <Button onClick={() => onClickPutLabelsInGT()}>Labels in GT</Button> */}
      {/* <Button onClick={() => onClickPullMap({ lobby: true })}>
        Pull Lobby Map
      </Button> */}
      {poster.pdfUrl && (
        <div>
          <object
            className='center'
            data={poster.pdfUrl}
            height='600px'
            width='100%'
            aria-label='Poster'
          ></object>
        </div>
      )}
      <br />
      {poster.videoUrl && (
        <div>
          <video
            src={poster.videoUrl}
            height='500px'
            width='100%'
            controls
            type='video/mp4'
          />
        </div>
      )}
      {/* <Comments parentId={posterId} posterId={posterId} /> */}
    </div>
  )
}

export default withAuthentication(Poster)
