export const studentSession = {
  date: 'Sunday, June 11',
  slack: 'gemworkshop.slack.com/app_redirect?channel=0-student',
  blocks: {
    0: {
      groups: ['Student Session'],
      time: '8:00-8:30',
      name: 'Intro to GEM',
      host: 'Hsinju Chen and Austin Smith',
    },
    1: {
      groups: ['Student Session'],
      time: '8:30-9:00',
      name: 'Fundamentals of Plasma Physics and Magnetosphere',
      host: 'Yang Zhang',
    },
    2: {
      order: 2,
      groups: ['Student Session'],
      time: '9:00-9:30',
      name: 'Dayside Magnetosphere',
      host: 'Rebecca Harvey',
    },
    3: {
      order: 3,
      groups: ['Student Session'],
      time: '9:30-10:00',
      name: 'Coffee break',
    },
    4: {
      order: 4,
      groups: ['Student Session'],
      time: '10:00-10:30',
      name: 'Nightside Magnetosphere',
      host: 'Dinesh Kumar, Veypatty Radhakrishnan',
    },
    5: {
      order: 5,
      groups: ['Student Session'],
      time: '10:30-11:00',
      name: 'Inner Magnetosphere',
      host: 'Shujie Gu',
    },
    6: {
      order: 6,
      groups: ['Student Session'],
      time: '11:00-11:30',
      name: 'Magnetosphere-Ionosphere Coupling',
      host: 'Domenique Freund',
    },
    7: {
      order: 7,
      groups: ['Student Session'],
      time: '11:30-1:00',
      name: 'Lunch',
      room: 'L',
    },
    8: {
      order: 8,
      groups: ['Student Session'],
      time: '1:00-1:30',
      name: 'Global System Modeling',
      host: 'Yang Mei',
    },
    9: {
      order: 9,
      groups: ['Student Session'],
      time: '1:30-2:00',
      name: 'Data Science',
      host: 'Hongfan Chen',
    },
    10: {
      order: 10,
      groups: ['Student Session'],
      time: '2:00-2:30',
      name: 'Coffee Break',
    },
    11: {
      order: 11,
      groups: ['Student Session'],
      time: '2:30-3:00',
      name: 'Space Weather',
      host: 'Espen Fredrick',
    },
    12: {
      order: 12,
      groups: ['Student Session'],
      time: '3:00-3:30',
      name: 'NSF Office Hour',
      host: 'Chia-Lin Huang, Christine Gabrielse, Joseph Westlake',
    },
  },
}
