import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FirebaseContext } from '../../server/Firebase'
import { AuthUserContext } from '../Session'
import { GROUPS } from '../../constants/splinterGroups'
import { BLOCKS } from '../../constants/blocks'
import { TalksContext } from '../SplinterGroup/context'
import { FormCheck } from 'react-bootstrap'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'
import { PencilSquareIcon } from '@heroicons/react/24/solid'

const AddQuestionModal = ({ splinterGroup, blockId, talkOrder, ...props }) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState(user.name)
  const [question, setQuestion] = useState('')
  const [talkId, setTalkId] = useState('general')
  const handleToggle = () => setShowModal(!showModal)

  const blocks = [
    ...GROUPS[splinterGroup].blocks.map((blockId) => {
      const day = blockId.split('-')[0]
      return Object.values(BLOCKS[day]).find((block) => block.id === blockId)
    }),
  ]
  const block = blocks.find((block) => blockId === block.id)

  return (
    <div>
      <Button type='button' variant='success' onClick={() => handleToggle()}>
        Ask a question!
      </Button>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Ask a question!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              const splinterGroups = block.groups.includes(splinterGroup)
                ? [splinterGroup]
                : block.groups.find(
                    (elem) =>
                      (typeof elem !== 'string') & elem.includes(splinterGroup)
                  )
              await firebase.postQuestion({
                splinterGroups: splinterGroups,
                data: {
                  name,
                  question,
                  talkId,
                  email: user.email,
                  createdAt: Date.now(),
                  blockId: blockId,
                },
              })
              setShowModal(false)
            }}
          >
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                defaultValue={name}
                type='text'
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <br />
            <Form.Group controlId='Question'>
              <label class='form-label' for='textAreaExample'>
                {'Your Question'}
              </label>
              <textarea
                class='form-control'
                id='textAreaExample1'
                rows='4'
                defaultValue={''}
                onChange={(e) => setQuestion(e.target.value)}
              ></textarea>
            </Form.Group>
            <ChooseTalkButton
              setTalkId={setTalkId}
              splinterGroup={splinterGroup}
              talkOrder={talkOrder || []}
            />
            <br />
            <Button variant='primary' type='submit' disabled={!question.length}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const EditQuestionModal = ({
  initialQ,
  splinterGroup,
  blockId,
  talkOrder,
  className,
  ...props
}) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState(user.name)
  const [question, setQuestion] = useState(initialQ.question || '')
  const [talkId, setTalkId] = useState(initialQ.talkId || 'general')
  const handleToggle = () => setShowModal(!showModal)
  const blocks = [
    ...GROUPS[splinterGroup].blocks.map((blockId) => {
      const day = blockId.split('-')[0]
      return Object.values(BLOCKS[day]).find((block) => block.id === blockId)
    }),
  ]
  const block = blocks.find((block) => blockId === block.id)

  return (
    <div>
      <span className={className} onClick={() => handleToggle()}>
        <PencilSquareIcon title='Edit' className='h-8 w-8' />
      </span>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit your question!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              const splinterGroups = block.groups.includes(splinterGroup)
                ? [splinterGroup]
                : block.groups.find(
                    (elem) =>
                      (typeof elem !== 'string') & elem.includes(splinterGroup)
                  )
              await firebase.updateQuestion({
                question: {
                  ...initialQ,
                  name,
                  question,
                  talkId,
                  blockId: block.id,
                },
              })
              setShowModal(false)
            }}
          >
            {user.email === initialQ.email && (
              <div>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    defaultValue={name}
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Form.Group controlId='Question'>
                  <label class='form-label' for='textAreaExample'>
                    {'Your Question'}
                  </label>
                  <textarea
                    class='form-control'
                    id='textAreaExample1'
                    rows='4'
                    defaultValue={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  ></textarea>
                </Form.Group>
                <ChooseTalkButton
                  setTalkId={setTalkId}
                  splinterGroup={splinterGroup}
                  talkOrder={talkOrder || []}
                  isDefaultChecked={talkId}
                />
                <br />
                <Button
                  variant='primary'
                  type='submit'
                  disabled={!question.length}
                >
                  Submit
                </Button>
              </div>
            )}
          </Form>
          {(user.isAdmin || user.email === initialQ.email) && (
            <div className='flex flex-col items-center'>
              <h3 className='left pt-4 pb-2'>
                Do you want to delete this question?
              </h3>
              <Button
                variant='danger'
                onClick={() => {
                  firebase.deleteQuestion({
                    questionId: initialQ.id,
                  })
                  handleToggle()
                }}
              >
                I want to delete this question
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ChooseTalkButton = ({
  setTalkId = () => {},
  talkOrder,
  isDefaultChecked = 'general',
}) => {
  const talks = useContext(TalksContext)
  return (
    <div>
      <div class='p-2 pl-0'>What talk is your question for?</div>
      <FormCheck type='radio' key={talkOrder.join()}>
        <FormCheckInput
          type='radio'
          value={'general'}
          id={'general'}
          name={'relevantTalk'}
          onClick={(e) => {
            setTalkId('general')
          }}
          defaultChecked={isDefaultChecked === 'general'}
        ></FormCheckInput>
        <FormCheckLabel for={'general'}>General</FormCheckLabel>
      </FormCheck>
      {talkOrder.map((talkId) => {
        const talk = talks[talkId]
        return (
          <FormCheck type='radio' key={talk.name + '-' + talk.title}>
            <FormCheckInput
              type='radio'
              value={talkId}
              id={talkId}
              name={'relevantTalk'}
              onClick={(e) => {
                setTalkId(talkId)
              }}
              defaultChecked={isDefaultChecked === talkId}
            ></FormCheckInput>
            <FormCheckLabel>{talk.name + '-' + talk.title}</FormCheckLabel>
          </FormCheck>
        )
      })}
    </div>
  )
}

export { AddQuestionModal, EditQuestionModal }
